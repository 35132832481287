<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          {{ $t("Publisher: ") + record.publishing_name }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col cols="12" sm="4" md="3" lg="2">
            <v-img :src="record.photo" contain height="150px" />
          </v-col>
          <v-col cols="12" sm="8" md="9" lg="10">
            <v-row dense>
              <v-col cols="12">
                <div>{{ $t("Institutions") }}</div>
                <div v-for="ins in record.affiliations.institutions" :key="ins.ids.id" class="text-h3">
                  {{ ins.name }}
                </div>
              </v-col>
              <v-col cols="12" class="d-flex">
                <div>{{ $t("Publishing names: ") }}</div>
                <div v-for="ins in record.publishing_names" :key="ins" class="ml-2">
                  <v-chip class="text-h4" small label>{{ ins }}</v-chip>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("RID") }}</div>
                <div class="text-h4">{{ record.ids.rid }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("ORCiD") }}</div>
                <div class="text-h4">{{ record.ids.orcid }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("RIDS") }}</div>
                <div v-for="id in record.ids.rids" :key="id" class="text-h4">{{ id }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("TruID") }}</div>
                <div class="text-h4">{{ record.ids.truid }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("Publications") }}</div>
                <div class="text-h3">{{ record.publications.count }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("Editorials") }}</div>
                <div class="text-h3">{{ record.editorial_boards.count }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("Reviews pre") }}</div>
                <div class="text-h3">{{ record.reviews.pre.count }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <div>{{ $t("Reviews post") }}</div>
                <div class="text-h3">{{ record.reviews.post.count }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12">
            <v-tabs>
              <v-tab>{{ $t("Publications") }}</v-tab>
              <v-tab>{{ $t("Reviews") }}</v-tab>
              <v-tab>{{ $t("Editorials") }}</v-tab>
              <v-tab-item>
                <v-data-table
                  locale="en"
                  item-key="ids.id"
                  :headers="headersP"
                  :items="record.publications.publication_list"
                  class="elevation-1"
                >
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  locale="en"
                  item-key="ids.id"
                  :headers="headersR"
                  :items="record.reviews.review_list"
                  class="elevation-1"
                />
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  locale="en"
                  item-key="ids.id"
                  :headers="headersE"
                  :items="record.editorial_boards.editorial_boards_list"
                  class="elevation-1"
                />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,
      headersP: [
        { text: "Publication", value: "publication.title" },
        { text: "Date published", value: "publication.date_published" }
      ],
      headersE: [
        { text: "Journal", value: "journal.name" },
        { text: "Verified", value: "verified" },
        { text: "Current", value: "current" }
      ],
      headersR: [
        { text: "Publisher", value: "publisher.name" },
        { text: "Verified", value: "verification.verified" },
        { text: "Date reviewed", value: "date_reviewed" }
      ],
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Publons",
          disabled: false,
          href: "/publons"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "PublonsPublisher") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get('infuni_publons?where={"_id":"' + this.id + '"}')
        .then(response => {
          this.record = response.data._items[0]
          if (this.record.publications.publication_list) {
            this.record.publications.publication_list.sort((a, b) => {
              if (a.publication.date_published && b.publication.date_published) {
                return b.publication.date_published.substr(0, 4) - a.publication.date_published.substr(0, 4)
              }
              return b.publication.date_published - a.publication.date_published
            })
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
